
import { ref, watch } from 'vue';
import CardTable from '@/components/kt-datatable/CardTable.vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import ApiService from '@/core/services/ApiService';
import { hideModal } from '@/core/helpers/dom';
import { reinitializeComponents } from '@/core/plugins/keenthemes';

export default {
  name: 'ManageSubModel',
  components: {
    CardTable,
  },
  setup() {
    const breadcrumbs = ['Car & Function', ['Manage Car Sub Model']];
    const tableHeader = [
      {
        key: 'no',
        name: 'No.',
        sortable: true,
      },
      {
        name: 'Sub Model',
        key: 'name',
        sortable: true,
      },
      {
        name: 'Model',
        key: 'model',
        sortable: true,
      },
      {
        name: 'Actions',
        key: 'actions',
        sortable: false,
      },
    ];

    const currentIndex = ref(-1);
    const models = ref([]);
    const tableData = ref([]);
    const loading = ref(true);
    const modalRef = ref(null);
    const inputRef = ref({
      name: '',
      model: '',
    });
    const tableRef = ref<any>(null);
    const filterData = ref<any>([]);

    watch(
      () => [tableRef.value?.rowsPerPage, tableRef.value?.currentPage],
      async ([rows, page]) => {
        if (!models.value.length) {
          const modelData = await ApiService.get(
            'car-brand-model?page=1&pageSize=1000000'
          );
          models.value = modelData.data.datas;
          const modelsCopy: any = [...models.value];
          modelsCopy.unshift({ name: 'Model', key: 'model' });

          filterData.value.push(modelsCopy);
        }

        const res = await ApiService.get(
          `car-brand-series?page=${page}&pageSize=${rows}`
        );
        tableData.value = res.data.datas;
        tableRef.value.total = res.data.total;

        reinitializeComponents();
        loading.value = false;
      }
    );

    const create = async () => {
      await ApiService.post('car-brand-series', {
        name: inputRef.value.name,
        model: inputRef.value.model,
      });

      hideModal(modalRef.value);

      Swal.fire({
        text: 'New Sub Model has been created',
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Return to Sub Model Lists',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      });

      inputRef.value = {
        name: '',
        model: '',
      };

      await tableRef.value.getData(
        tableRef.value.currentPage,
        tableRef.value.rowsPerPage
      );

      reinitializeComponents();
    };

    return {
      tableHeader,
      breadcrumbs,
      currentIndex,
      models,
      tableData,
      loading,
      modalRef,
      inputRef,
      create,
      tableRef,
      filterData,
    };
  },
};
